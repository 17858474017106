import React from "react"
import Layout from "../components/layout"

const Entry = ({ entry }) => {
	return (
		<li>
			<a href={'/posts/' + entry.slug}>{entry.title}</a>
		</li>
	)
}

const IndexPage = ({ data }) => (
	<Layout>
		<div>
			<h1>Welcome</h1>
			<p>
				I am Spiros Martzoukos, a Senior Product Manager focused on Strategic alignment and Process improvement with experience in B2B SaaS products. After 6 years in Berlin, I'm currently located with my family of two children in Athens, Greece.
				<br/>
				You can find me on <a href="https://twitter.com/martzoukos">Twitter</a>,
				<a href="https://www.linkedin.com/in/spirosmartzoukos/">LinkedIn</a>,
				<a href="https://www.facebook.com/spiros.martzoukos">Facebook</a>,
				<a href="https://github.com/martzoukos/">Github</a>
			</p>
			<h2>Here's some content I created:</h2>
			<ul>
				{
					data.allContentfulPage.edges.map(
						(edge, i) => <Entry entry={edge.node} key={i} />
					)
				}
			</ul>			
		</div>
	</Layout>
)

export default IndexPage

export const Pages = graphql`
query Pages {
  allContentfulPage (
    filter: {
      title: { 
        ne:"IGNORE" 
      }
    }
  ) {
    edges {
      node {
        title
        slug
      }
    }
  }
}
`;
